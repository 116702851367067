.AlertText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #FF9500;

}

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box2 {
    position: relative;
    width: 30%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: 5vh;
    /* margin-top: calc(100vh - 85vh - 20px); */
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}