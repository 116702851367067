.containers {
  display: flex;
  flex-wrap: 2;
  justify-content: left;
  padding: 0;
  margin: 0;
  color: #FFFFFF;
  box-sizing: border-box;
  word-wrap: break-word;
  width: 100%;
  height: 100vh;
  color: white;
  overflow: hidden;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #FFF0 inset !important;
}



.login-container-6 {
  height: 100vh;
  background-image: url('../../assets/login/login_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  flex: 1.3;
  background-size: contain;
  /* border: 1px solid black; */
  background-color: #c5ddf4;
}



.login-image {
  flex: 1;
  height: auto;

}

.login-container-4 {
  flex: 1;
  height: 100vh;
  /* border: 1px solid black; */

}

.login-container-head {
  display: grid;
  row-gap: 15px;
  text-align: left;
  letter-spacing: 1px;
  padding-top: 15vh;
  /* border: 1px solid black ; */
}

.login-heading-b {
  letter-spacing: 2px;
  font-size: 60px;
  line-height: 60px;
  font-weight: 500;
  text-shadow: 1px 2px 1px rgb(170, 159, 159);
  color: #1E6FE5;


}

.main-img {
  /* border:1px solid palevioletred; */
}

.login-heading-m {
  font-size: 30px;
  /* margin-top:10px; */
  margin-bottom: 20px;
  font-weight: 400;
  text-shadow: 1px 2px 1px rgb(170, 159, 159);
  color: #1E6FE5;
  /* margin-top: 15px; */
}

/* ------------------- x section start -------------------*/

.login-text-s {
  margin-bottom: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #222222;

}

.partition {
  height: 2px;
  width: 70px;
  margin-bottom: 12px;
  margin-top: 30px;
  ;
  background-color: #d5dff3;
}

/* ------------------- x section end -------------------*/


.containers::placeholder {
  color: white;
  font-family: Lato;
}


.containers::-ms-input-placeholder {
  color: white;
  font-family: Lato;
}

.login-input-container input ::placeholder {
  color: red;
}

.login_placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 11px;
  /* identical to box height, or 85% */
  background: none;
  color: #000;
}


/* ::-webkit-input-placeholder {
  color: #FFFFFF;
 } */


/* .containers{
  padding: 0;
  margin: 0;
  color:#FFFFFF;
  box-sizing: border-box;
  word-wrap: break-word;
  font-family: Lato;
  font-family: Lato;
} */

.containers a {
  /* color: #FFFFFF; */
  text-decoration: unset;
}

.containers:span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #FFFFFF;
}

.login-input-container {
  padding-bottom: 24px;
  color: #FFFFFF;
}


.formbg {
  min-height: calc(100vh);
  text-align: left;
  margin: 0px auto;
  padding: 0 20%;
  padding-bottom: 0;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  border: 1ps solid blue;
}

.reset-pass {
  margin: 10px 0px;
  font-size: 16px;
  font-weight: 300;
  display: block;
  color: #FFFFFF;
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
  /* color: #FFFFFF; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}



.login-input-container input {
  font-size: 14px;
  line-height: 28px;
  padding: 8px 17px;

  width: calc(100% - 10px);
  /* min-height: 44px; */
  border: unset;
  margin-top: 5px;
  border-radius: 4px;
  outline: unset;
  background-color: #FFF;
  /* border: 1px solid black; */
  border: 1px solid rgba(106, 106, 106, 0.6);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(79, 161, 233, 0.26));
  border-radius: 5px;

}

.login-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: calc(100% - 10px);
  height: 46px;
  font-weight: 600;
  margin-top: 5px;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: #1F77E6;
  cursor: pointer;
  color: #ffffff;
  margin-top: 30px;
  /* border: 1px solid blueviolet; */

}

.login-home-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: calc(100% - 10px);
  height: 40px;
  font-weight: 600;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: #1F77E6;
  cursor: pointer;
  color: #ffffff;
  margin-top: 20px;
  /* border: 1px solid blueviolet; */

}

/* .login-submit-button:hover{
  border:1px solid #b4b3b3;
} */

.login-password-field {
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 14px;
  line-height: 28px;
  padding: 12px 17px;
  width: calc(100% - 10px);
  /* height: 44px; */
  border: unset;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(106, 106, 106, 0.6);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(79, 161, 233, 0.26));
  border-radius: 5px;
}

.login-password-field input {
  margin: 0 10px;
  font-size: 14px;
  width: 90%;
  border: unset;
  outline: unset;

}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}


.password-eye {
  cursor: pointer;
}

.login-submit-button i {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 16px;
  margin-left: 8px;
  background-color: #FFFFFF;
  width: 18px;
  height: 18px;
  padding: 0;
  color: #1F77E6;
  /* border: 1px solid blueviolet; */
}

.ssolink {
  display: block;
  text-align: center;
  font-weight: 500;
  justify-content: center;

  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #222222;
  margin-top: 20px;
  /* color: #222222; */
  cursor: pointer;
  /* border: 1px solid blueviolet; */
  width: calc(100% - 10px);

}

@media (max-width:800px) {
  .login-container-6 {
    flex: 0;

  }

  .formbg {
    padding: 5%;
  }
}


@media (max-width:600px) {
  .login-heading-b {
    font-size: 50px;

  }

  .login-heading-m {
    font-size: 24px;
  }
}


.hold-on-msg {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #222222;

}

.hold-on-messages {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #708091;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hold-button {
  background: linear-gradient(0deg, #1953E7 -22.83%, #30D3E0 118.48%);
  box-shadow: 4px 4px 8px rgba(33, 131, 228, 0.26);
  border-radius: 5px;
  color: #FAFAFA;
  width:"100%";
  height: 46px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 34px;
}

.holdButtondiv {
  display: flex;
  flex-direction: row-reverse;
  margin-right: -38px;
}

.notesDiv {
  background: #FFFFFF;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08), -1px -1px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding-bottom: 20px;
}

.notemsg {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  letter-spacing: 0.04em;
  color: #1E2228;
  transform: rotate(0.05deg);
  margin-top: 18px;


}

.notetitlediv {
  background: #FF9500;
  opacity: 0.2;
  width: 6px;
  height: 44px;
  border-radius: 3px 0px 0px 0px;
}

.notesdivtitle {
  border-bottom: 0.5px solid #E5E0EB;
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;

  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
}

.noteFormat {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 128%;
  color: #FF9500;
  margin-left: 20px;
}